.dashboard_main_section {
  background-size: cover;
  background-repeat: no-repeat;

  .black_back {
    background-color: rgb(18 18 30 / 59%);
  }

  .minecraft_main_section_txt {
    min-height: 350px;
    padding-top: 2.5rem;
    text-align: center;

    h1 {
      color: white;
      font-weight: bold;
      font-size: 2.5rem;
    }

    h2 {
      color: white;
      font-weight: bold;
      font-size: 1.5rem;
    }

    button {
      margin-top: 3rem;
      color: white;
      background-color: rgba(0, 0, 0, .8);
      padding: 15px 2.5rem;
      text-transform: uppercase;
      border: 1px solid #00a9ff;
      font-size: 15px;
      transition: .2s ease-in-out;
      -webkit-transition: .2s ease-in-out;
      cursor: pointer;
      margin-right: 5px;

      &:hover {
        background-color: #00a9ff;
      }
    }
  }

  .main_section_txt {
    padding-top: 5rem;
    max-width: 880px;

    h1 {
      color: white;
      font-weight: bold;
      font-size: 5rem;
    }

    .main_section_features {
      span {
        font-size: 1.2rem;
        font-weight: bold;
        color: white;
      }
    }

    #main_section_go_btn {
      border: none;
      background: #4d99ff;
      color: white;
      padding: 0.8rem 2rem;
      font-weight: bold;
    }
  }

  .main_section_footer {
    text-align: center;
    color: white;
    padding: 1rem 0 3rem 0;
  }
}


.game_server_table_wrapper {
  .not_exist_table_main_txt {
    h4 {
      color: white;
    }
  }

  .game_server_table {
    .game_table_row {
      td {
        font-weight: bold;
      }

      .game_instance_more_btn {
        svg {
          fill: white;
        }
      }
    }

    thead {
      background-color: var(--dark-bg-color1);

      th {
        color: white;
        border: none;
      }
    }

    tbody {
      tr {
        background-color: #404040;

        &:hover {
          background-color: rgba(0, 0, 0, 0.72);
        }

        td {
          padding: 22px !important;
          border-color: #464646;
          color: white;
        }
      }
    }

    .game_server_region_img {
      img {
        max-width: 35px;
      }

      span {
        margin-left: 0.3rem;
        font-weight: bold;
      }
    }
  }

  .MuiTablePagination-root {
    background-color: #404040;
    color: white;

    svg {
      fill: white;
    }
  }

  .game_backup_not_exist_wrapper{
    min-height: 300px;
    position: relative;
    .not_exist_txt{
      text-align: center;
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      span{
        display: block;
        color: white;
        font-weight: bold;
      }
      button{
        margin-top: 1rem;
        padding : 0.6rem 2rem;
      }
    }
  }
}


.game_manage_dashboard {
  .game_server_status_wrapper {
    padding: 2rem 0;

    .game_status_graph_wrapper {
      .apexcharts-tooltip {
        color: black !important;

        span, p {
          color: black !important;
        }
      }

      span, p {
        color: black !important;
      }
    }

    .game_status_title {
      padding: 0.8rem 0.5rem;

      span {
        font-weight: bold;
        font-size: 1.2rem;
      }
    }

    .game_status_wrapper {
      z-index: 2;
      display: flex;
      flex-direction: row;
      border-radius: 20px;
      padding: 1rem;
      background-color: var(--dark-bg-color2);
      justify-content: space-between;
      align-items: center;

      .game_status_value {
        padding: 0 2rem;
        text-align: center;
        font-weight: bold;
        font-size: 1.4rem;

        span {
          &:nth-child(2) {
            margin: 0 1rem;
          }
        }
      }

      & > .MuiBox-root {
        & > .MuiBox-root {
          border: 7px solid rgba(128, 128, 128, 0.43);
          border-radius: 50%;
        }
      }

      .cpu_circle_progress {
        color: #4d99ff !important;
      }

      .ram_circle_progress {
        color: limegreen !important;
      }

      .user_circle_progress {
        color: #e5c7ca !important;
      }
    }

    .game_server_info {
      padding-bottom: 3rem;

      .game_access_address {
        display: flex;
        flex-direction: row;
        align-items: center;

        svg {
          cursor: pointer;
          fill: white;
        }
      }
    }

  }
}


@media (max-width: 880px) {
  .minecraft_main_section_txt {
    h1 {
      font-size: 1.5rem !important;
    }
  }
}
