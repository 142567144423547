.script_list_wrapper {
  .script_list_header {
    padding: 3rem 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    h2 {
      font-weight: bold;
    }
  }

  .script_content {
    margin-top: 48px;
    background-color: white;

    table {
      tbody {
        .txt_table_tr {
          cursor: pointer;
        }
      }
    }
  }
}

.script_modal {
  max-width: 700px !important;

  #script_txt_label {
    color: var(--bright-txt-color);
    font-size: 0.875rem;
  }
}

.script_detail_wrapper {
  padding: 2rem 1rem;
  text-align: left;

  .script_table_txtArea {
    padding: 1rem;
    border-color: rgba(0, 0, 0, 0.15);
    color: rgba(0, 0, 0, 0.58);
  }

  .script_detail_btn {
    button {
      width: 100%;
    }
  }
}

#script_type_select {
  fieldset {
    border-radius: 12px;
  }
}

@media (max-width: 880px) {
  .script_detail_wrapper {
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.15);

    .script_table_txtArea {
      min-height: 100px !important;
    }

    .script_name_input {
      margin-top: 1rem;
    }
  }
  #script_name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  #script_type {
    color: #0a77d5;
  }
  .mobile_script_more_btn {
    color: grey;
    transition-duration: 1s;
  }
  .open_script {
    transform: rotate(90deg);
  }
}
