:root {
  --bright-txt-color: rgba(0, 0, 0, 0.69);
  --bright-bg-color: #F4F8FA;
  --dark-bg-color1: #242527;
  --dark-bg-color2: #303030;
  --dark-bg-color3: #18191B;
  --origin-bg-color: #F7F9FA;
}

.text-danger {
  color: red !important;
}

.dark_bg_color1 {
  background-color: var(--dark-bg-color1) !important;

  span, label, p {
    color: white;
  }
}

.dark_bg_color2 {
  background-color: var(--dark-bg-color2) !important;

  span, label, p {
    color: white !important;
  }
}

.dark_bg_color3 {
  background-color: var(--dark-bg-color3) !important;

  span, label, p {
    color: white;
  }
}

.origin_bg_color {
  background-color: var(--origin-bg-color);
}

.dark_mode_txt {
  color: white !important;
}

.dark_table_mode {
  thead {
    th {
      background-color: var(--dark-bg-color2) !important;
    }
  }

  tbody {
    .ticket_row_wrapper {
      background-color: var(--dark-bg-color1) !important;
    }

    tr {
      background-color: #404040 !important;

      td {
        color: white;
      }
    }
  }
}

.dark_table_pagination {
  background-color: #404040 !important;

  .MuiTablePagination-select {
    color: white;
  }

  svg {
    fill: white;
  }
}

@font-face {
  font-family: 'paybooc-Bold';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-07@1.0/paybooc-Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

html, body, #root {
  height: 100% !important;
}

main {
  min-height: 100vh;
}


body {
  margin: 0 !important;
  padding: 0 !important;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: none; /* IE and Edge */
}

main {
  position: relative;
  height: 100%;
  padding: 0 !important;
}

span {
  font-family: 'paybooc-Bold', sans-serif !important;
}

header {
  background-color: white !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 1px 1px !important;
}

body::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera*/
}


a {
  text-decoration: none !important;
  color: white;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0 !important;
}

p {
  font-family: 'paybooc-Bold', sans-serif !important;
  margin: 0 !important;
}

.content_init {
  background-color: #F7F9FA;
  height: calc(100vh - 110px);

}

h1, h2, h3, h4 {
  font-family: 'paybooc-Bold', sans-serif !important;
  margin: 0 !important;
}


fieldset {
  legend {
    float: unset;
  }
}


.MuiTooltip-tooltip {
  font-size: 0.875rem !important;
}

@media (min-width: 880px) {
  .modal {
    margin-left: 119px !important;
  }
}

@media (max-width: 880px) {
  .MuiTablePagination-root {
    margin-bottom: 5rem !important;
  }
}
