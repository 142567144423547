#main_logo_img {
  width: 70%;
  margin-left: 1rem;
}

.manage_wrapper {
  margin-top: 2rem;

  .manage_header {
    background-color: white;
    padding: 3rem 1rem;
    border-radius: 5px;

    .server_info {
      .server_info_title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .manage_os_title {
          padding: 0 1rem;
          display: flex;
          align-items: center;

          .manage_label {
            margin-left: 1rem;

            &.game_manage_label {
              input {
                color: white;
              }
            }
          }

          img {
            width: 64px;
          }

          #status_circle_run {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            margin-right: 0.3rem;
          }

          .run_status {
            color: green;

            #status_circle_run {
              background-color: green;
            }
          }

          .start_status {
            color: #4d99ff;

            #status_circle_run {
              background-color: #4d99ff;
            }
          }

          .error_status {
            color: orange;

            #status_circle_run {
              background-color: orange;
            }
          }

          .stop_status {
            color: red;

            #status_circle_run {
              background-color: red;
            }
          }

        }

        .manage_os_control {
          display: flex;
          flex-direction: row;

          #server_power_btn {
            color: white;
            background-color: #4596DD;

            &:hover {
              color: #4596DD;
              background-color: #abd7fc;
            }
          }

          button {
            border-radius: 5px;
            padding: 0.5rem 1rem;
            margin-right: 1.2rem;
            background-color: #E0E7ED;
            color: #4596DD;
            border: none;
            font-weight: bold;

            &:first-child {
              min-width: 150px;
              min-height: 41px;
            }

            &:nth-child(2) {
              min-width: 176px;
              min-height: 41px;
            }

            &:hover {
              color: #4596DD;
              background-color: #abd7fc;
            }
          }


        }

      }
    }
  }
}

.game_manage_menu_item, .manage_menu_item {
  padding: 0.7rem !important;
  color: #4596DD !important;

  svg {
    margin-right: 0.6rem
  }


}

.manage_menu_item {
  &:last-child {
    button {
      color: red !important;
    }
  }
}

.manage_dashboard_wrapper {
  border-radius: 5px;
  background-color: white;
  padding: 2rem;

  .dashboard_server_info {
    h2 {
      color: rgba(0, 0, 0, 0.68);
    }

    .server_info_content {
      .server_info_item {
        .imgInfo_wrapper {
          padding: 1rem 0;
        }
      }

      .hide_pwd {
        margin-left: 0.5rem;
        color: #999999;
        cursor: pointer;

        &:hover {
          color: #4596DD;
        }
      }

    }
  }


}

.bg_main {
  background: #4b6cb7; /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #182848, #4b6cb7); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #182848, #4b6cb7); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}

.manage_setting_wrapper {
  min-height: 400px;
  padding: 1rem;

  .setting_ip_wrapper {
    padding: 0 1rem;

    .ip_v4 {
      margin: 2rem 0;

      .ip_v4_title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }

    .not_ip_v6 {
      button {
        padding: 1rem 2.5rem;
      }
    }

    .my_table_wrapper {
      padding: 2rem 0;
    }
  }

  #setting_tabs {
    width: 150px;
  }

  .change_setting_wrapper {
    width: 100%;
    padding: 0 1rem;

    .change_ddos_wrapper {
      margin-top: 2rem;
    }

    .change_os_wrapper, .change_iso_wrapper,
    .change_plan_wrapper, .change_hostname_wrapper {
      margin-top: 2rem;
      width: 60%;
    }

    button {
      font-weight: bold;
      font-size: 1.1rem;
      margin-top: 1rem;
      padding: 0.7rem;
    }

    .change_ddos_wrapper {
      button {
        width: 60%;
      }
    }

    .change_script_wrapper {
      .setting_scripts {
        .script_list_wrapper {
          padding: 1rem 0;
          margin-bottom: 1rem;
        }
      }

      button {
        width: 60%;
      }
    }
  }
}

.manage_snapshot_wrapper {
  padding: 1rem;

  .snapshots_table_wrapper {
    margin-top: 3rem;
  }

  .snapshots_header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

.manage_backup_wrapper {
  .manage_backup_detail {
    padding: 1rem;

    .manage_backup_schedule {
      #next_backup_time {
        margin-top: 0.5rem;
        color: grey;
        font-size: 0.8rem;
      }

      .schedule_box {
        padding-top: 1rem;

        .schedule_select {
          padding: 1rem 0;
        }

        .backup_schedule_btn {
          button {
            padding: 1rem 0;
            font-weight: bold;
            font-size: 1rem;
          }
        }
      }
    }


    .manage_backup_history {
      margin-top: 1rem;
      padding: 1rem;

      .my_table_wrapper {
        margin-top: 3rem;
      }

      .txt_table_tr {
        td {
          &:first-child {
            font-weight: bold;
          }

          &:nth-child(3) {
            font-weight: bold;
          }
        }
      }
    }

    .manage_backup_active {
      .manage_backup_active_btn {
        margin-top: 2.5rem;
        padding: 1rem 0;
        font-weight: bold;
        font-size: 1rem;
      }
    }
  }

  .enable_backup_btn {
    padding: 1rem;
    font-size: 1.1rem;

    svg {
      margin-right: 0.4rem;
    }
  }
}

.manage_box {
  min-height: 350px;
  border-radius: 5px;
  background-color: white;
  padding: 2rem;
}

.not_manage_exist_wrapper {
  background-color: white;
  margin-top: 2rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 15px;

  span {
    color: #5e5e5e;
  }

  button {
    margin-top: 2rem;
    border: none;
    padding: 1rem 2.5rem;
    background-color: white;
    color: #4D99FF;
    border-radius: 30px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

    &:hover {
      background-color: #4D99FF;
      color: white;
    }
  }

  #not_manage_backup_txt {
    margin-top: 2rem;
    font-weight: bold;
    font-size: 1.1rem;
    color: #4D99FF;
  }
}


@media (max-width: 880px) {
  .server_info_title {
    flex-direction: column !important;

    .manage_os_title {
      padding: 0 !important;
      width: 100%;
      flex-direction: row;
      align-items: flex-start !important;

      img {
        max-width: 50px;
      }
    }

    .manage_os_control {
      width: 100%;
      margin-top: 2rem;
      justify-content: flex-end;

      button {
        font-size: 0;
        min-width: 30px !important;
      }
    }
  }
  .manage_dashboard_wrapper {
    padding: 0 !important;

    .dashboard_server_info {
      padding: 0.5rem;

      .server_info_item {
        .imgInfo_wrapper {
          margin-top: 0.3rem;
          padding: 0 !important;

          .imgInfo_img_wrapper {
            display: none;
          }
        }
      }
    }
  }
}

@media (max-width: 880px) {
  .manage_backup_history {
    .mobile_table_wrapper {
      margin-top: 2rem;
    }
  }
  .ip_v4_title {
    button {
      font-size: 0;
    }
  }
  .change_setting_wrapper {
    .change_ddos_wrapper {
      margin-top: 2rem;

      button {
        width: 100% !important;
      }
    }

    .change_os_wrapper, .change_iso_wrapper,
    .change_plan_wrapper, .change_hostname_wrapper {
      margin-top: 2rem;
      width: 100% !important;
    }
  }
}

@media (max-width: 1200px) {
  #setting_tabs {
    width: 100% !important;
  }
}
