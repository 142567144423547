.chart_title {
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem 1rem 0 0;

  span {
    margin-left: 1rem;
    font-size: 1.2rem;
    color: grey;
    font-weight: bold;
  }
}

.traffic_total_wraper {
  padding-top: 1rem;
  span{
    font-weight: bold;
  }
  .inBound_traffic_item {
    span {
      color: #6ED3FF;
    }
  }

  .outBound_traffic_item {
    margin-top: 0.3rem;
    span {
      color: #1497FF;
    }
  }
}

.total_bandwidth_chart_wrapper {
  background-color: white;
}
