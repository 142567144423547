.snapshot_list_wrapper {
  .snapshots_header {
    padding: 3rem 0;
    display: flex;
    justify-content: space-between;

    h2 {
      font-weight: bold;
    }
  }

  .snapshots_table_wrapper, .backups_table_wrapper {
    background-color: white;
    padding-top: 3rem;
  }
}

.snapshot_detail_wrapper {
  padding: 2rem 1rem;

  .snapshot_detail_info {
    color: rgba(0, 0, 0, 0.68);

    .detail_info_left, .detail_info_right {
      display: flex;
      flex-direction: column;
      justify-content: center;

      span {
        padding: 0.4rem 0;
      }
    }

    .detail_info_right {
      font-weight: bold;
    }
  }

  .detail_right {
    display: flex;
    align-items: center;

    .detail_cost_wrapper {
      h4 {
        color: #0a77d5;
      }

      small {
        color: rgba(0, 0, 0, 0.35);
      }
    }
  }

  .snapShot_detail_btn {
    padding: 1rem 0;

    button {
      width: 100%;
    }
  }
}

.take_snapshot_info {
  margin: 0;
  color: rgba(10, 119, 213, 0.8);
  padding: 1.5rem !important;
  list-style: circle;
}

@media (max-width: 880px) {
  .instance_label_wrapper {
    span, small {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }
  .manage_snapshot_wrapper {
    .snapshots_header {
      margin-top: 2rem;
    }

    padding: 0 !important;

    .mobile_table_wrapper {
      padding: 0 !important;

      .mobile_item {
        width: 100%;
        margin: 0;
        background-color: white;


      }

      .snapshot_detail_wrapper {
        border: 1px solid rgba(0, 0, 0, 0.09);
        background-color: white;
        padding: 1rem !important;

        .snapshot_detail_row {
          flex-direction: column;
          font-size: 0.8rem;

          .snapShot_detail_btn {
            margin-top: 1rem;
          }
        }
      }
    }
  }
}
