.notice_wrapper {
  margin: 3rem 0;

  .notice_event_banner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .notice_event_img {
      width: 1024px;
      overflow: hidden;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      text-align: center;

      img {
        width: 100%
      }
    }

    .notice_event_description {
      margin-bottom: 3rem;
      padding: 2rem 1rem;
      max-width: 1024px;
      width: 100%;
      background-color: #333D4C;
      color: white;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
    }
  }

  .notice_title_wrapper {
    padding: 3rem 0;

    h2 {
      font-weight: bold;
    }
  }

  .notice_content_wrapper {
    margin-top: 1rem;
    padding: 2rem 0;
    background-color: white;

    &.dark_bg_color1 {
      .notice_list_item {
        background-color: var(--dark-bg-color1) !important;
        color: white;

        .MuiChip-label {
          color: black;
        }

        small {
          color: white !important;
        }
      }

      .notice_list_footer {
        background-color: var(--dark-bg-color2) !important;
        .MuiTablePagination-select{
          color: white;
        }
        svg {
          fill: white;
        }
      }
    }

    .notice_type_chip_wrapper {
      display: flex;
      flex-direction: row;
      max-width: 1024px;
      justify-content: flex-start;
      margin: 0 auto;

      .notice_type_chip {
        min-width: 80px;
        padding: 0.2rem 0.5rem;
        border-radius: 50px;
        margin-left: 1rem;
        background: none;
        border: 1px solid rgba(0, 0, 0, 0.34);
        color: rgba(0, 0, 0, 0.42);
        font-size: 0.8rem;
        font-weight: bold;

        &.select {
          background-color: #4D99FF;
          color: white;
          border-color: #4D99FF;
        }
      }
    }

    .notice_list_wrapper {
      max-width: 1024px;
      margin: 2rem auto 0;

      .notice_list_item_wrapper {
        margin-top: 2rem;

        .notice_type_img {
          max-width: 270px;
          transition-duration: 1s;
          overflow: hidden;
          float: right;
          border-radius: 15px;
          img{
            max-width: 270px;
          }
        }

        &:hover {
          .notice_type_img {
            box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
            -webkit-transform: translateY(-10px);
            -moz-transform: translateY(-10px);
            -ms-transform: translateY(-10px);
            -o-transform: translateY(-10px);
            transform: translateY(-10px);
          }
        }
      }

      .notice_list_item {
        padding: 1rem 0;
        background-color: white;
        cursor: pointer;

        &:hover {
          h4 {
            color: #4D99FF;
          }
        }
      }

      #notice_date {
        font-weight: bold;
        color: rgba(0, 0, 0, 0.24);
      }

      .notice_item {
        padding: 1rem 0;

        .notice_item_content {
          h4 {
            font-weight: bold;
          }

          .notice_txt {
            color: var(--bright-txt-color);
            font-size: 0.875rem;
          }

          .notice_item_agent {
            padding: 1rem 0;
            font-size: 0.8rem;

          }
        }
      }


    }

    .notice_list_footer {
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      border-radius: 15px;
      background-color: white;
      max-width: 1024px;
      margin: 4rem auto 2rem;
    }
  }
}

@media (max-width: 880px) {
  .notice_event_img{
    width: 100% !important;
  }
}
