.mode_change_loader {
  width: 100%;
  height: 100vh;
  position: relative;
  &.dark_loader{
    background-color: var(--dark-bg-color1);
    .loader_wrapper{
    background-color: var(--dark-bg-color2);
      span{
        color: white;
      }
    }
  }
  &.origin_loader{
    background-color: rgba(205, 222, 255, 0.96);
    .loader_wrapper{
    background-color: white;
    }
  }
  .loader_wrapper {
    border-radius: 20px;
    width: 200px;
    height: 200px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

    .MuiCircularProgress-root{
      width: 60px!important;
      height: 60px!important;
    }
    .MuiCircularProgress-svg{
      color: #4d99ff;

    }
  }
}

.nav_contact {
  background-color: #DFE3ED;
  width: 100%;
}

.dark_mode_nav_wrapper {

  .MuiDrawer-paper {
    border-right: none !important;
  }
  .dark_toolbar{
    svg{
      fill: white;
    }

  }
}

.notice_nav_wrapper {
  color: black;
  cursor: pointer;

  .notice_title {
    font-weight: bold;
  }

  .notice_go {
    &:hover {
      color: #4d99ff;
    }
  }
}

.nav_top {
  position: sticky;
  top: 0;
  z-index: 999;
  background-color: #0C1E67;
  color: white;

  .nav_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

  }

  .nav_main_logo {
    padding: 1rem 0;
    cursor: pointer;
  }

  .nav_left_wrapper, .nav_right_wrapper {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .nav_item_wrapper {
    flex: 2;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .nav_item {
      padding: 1rem 1.2rem;
      margin-left: 11px;

      img {
        margin-left: 0.5rem;
      }

      .nav_item_multi_wrapper {
        position: relative;

        .nav_item_multi {
          cursor: pointer;
          font-weight: bold;

          .nav_item_title_white {
            color: white;
          }

          .nav_item_title_black {
            color: black !important;
          }

          img {
            filter: invert(37%) sepia(19%) saturate(496%) hue-rotate(169deg) brightness(95%) contrast(83%);
          }

          &:hover {
            img {
              filter: invert(1%) sepia(19%) saturate(496%) hue-rotate(169deg) brightness(95%) contrast(83%);
            }
          }
        }

        .nav_item_children {
          top: 40px;
          width: 200px;
          position: absolute;
          display: flex;
          flex-direction: column;
          background-color: white;
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

          a {
            color: #6f6f6f;

            &:hover {
              background-color: #526dff;
              color: white;
            }

            .nav_children_title {
              padding: 0.8rem 1rem;

              span {
                font-weight: bold;
              }
            }
          }
        }
      }


    }
  }

  .nav_right_wrapper {
    .black_btn {
      color: black;
    }

    a {
      border: 1px solid #6f6f6f;
      color: white;
      padding: 0.5rem 1.2rem;
      margin-left: 1rem;
      border-radius: 20px;
      background: none;

      &:first-child {
        border-color: #526dff;
        background: #526dff;

        &:hover {
          background: white;
          color: #526dff;
        }
      }

      &:last-child {
        border-color: #526dff;

        &:hover {
          background: #526dff;
        }
      }
    }
  }


}

.nav_list_wrapper {
  color: rgba(0, 0, 0, 0.75);

  &.dark_bg_color3 {
    color: white;

    svg {
      fill: white;
    }

    .visit_page {
      color: #4d99ff;
      border-color: #4d99ff;
      background-color: var(--dark-bg-color2);
    }
  }

  .visit_page {
    color: #0a77d5;
    border-left: 4px solid #0a77d5;
    background-color: #E8F7FF;

    span {
      font-weight: bold;
    }
  }
}

.session_expired_wrapper {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  -moz-transition: height 1s ease;
  -webkit-transition: height 1s ease;
  -o-transition: height 1s ease;
  transition: height 1s ease;

  &.show {
    height: 55px;
  }

  &.hide {
    height: 0;
  }

  span {
    color: #0a77d5;
  }

  button {
    margin-left: 0.4rem;
  }
}

.navbar_point_wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-end;
  padding: 0.5rem;

  .navbar_point_box {
    border-radius: 15px;
    padding: 1rem 0.3rem;
    background-color: #F6F9FA;
    flex: 1;
    color: #474747;

    p {
      color: var(--bright-txt-color);
      font-weight: bold;
      font-size: 0.6rem;
      white-space: pre-wrap;
    }

    .company_policy_wrapper {
      span {
        cursor: pointer;

        &:hover {
          color: #4D99FF;
        }
      }
    }
  }
}

.navbar_top_wrapper {
  display: flex;
  align-items: center;

  .mode_change_switch_wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: black;
  }
}

@media (max-width: 880px) {
  .notice_nav_wrapper{
    display: none;
  }
  .mode_change_switch_wrapper{
    label{
      display: none;
    }
  }
}
