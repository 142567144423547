.game_file_manager_wrapper {
  padding-bottom: 3rem;
  margin-top: 2rem;
  .file_manager_route {
    padding: 0.3rem 1rem;
    background-color: var(--dark-bg-color3);
    color: white;
    margin: 0;

    .file_upload_btn_wrapper {
      padding: 0;

      button {
        border: none;
        background: none;

        svg {
          width: 35px;
          height: 35px;
          fill: white;
        }
      }
    }
  }

  .file_manager_box {
    background-color: var(--dark-bg-color2);

    .file_row {
      margin: 0;
      height: 100%;
      min-height: 814px;
    }

    .file_list {
      .file_manager_item {
        max-height: 54px;
        cursor: pointer;
        margin: 0;
        padding: 0.5rem 0;
        border-bottom: 1px solid white;

        &:hover {
          background-color: #4d99ff;
        }

        .file_manager_icon {
          text-align: center;

          svg {
            width: 35px;
            height: 35px;
            fill: white;
          }
        }

        .file_manager_item_info {
          display: flex;
          flex-direction: column;

          .file_name {
            font-weight: bold;
            font-size: 0.875rem;
          }

          .file_type {
            font-size: 0.68rem;
          }
        }
      }
    }

    .file_info_box {
      .file_manager_item_box {
        .file_box_title {
          padding: 0 0.5rem;

          svg {
            fill: white;
            width: 35px;
            height: 35px;
          }

          span {
            margin-left: 0.3rem;
            font-weight: bold;
            color: white;
            font-size: 1.1rem;
          }
        }

        .file_box_info {
          margin-top: 2rem;

          .file_box_item {
            margin: 0;
            padding: 0.5rem;

            &:nth-child(2n) {
              background-color: var(--dark-bg-color2);
            }
          }
        }


        .file_action_btn_wrapper {
          padding: 2rem 1rem;
          display: flex;
          flex-direction: column;

          #del_file {
            background: red;
            border-color: red;
          }

          button {
            background: none;
            border: 2px solid #4d99ff;
            margin-top: 1rem;
            padding: 0.5rem 0;
            color: white;
            font-weight: bold;

            &:hover {
              background: #4d99ff;
              color: var(--dark-bg-color1);
            }
          }

        }


      }
    }
  }
}

.file_manager_editor_modal {
  max-width: 1000px !important;

  .modal-content {
    background-color: var(--dark-bg-color2);
    min-height: 800px;

    .file_manager_file_ta {
      min-height: 500px;
      background-color: black;
      color: white;
      padding: 0.5rem;
      max-height: 800px;
      overflow-y: scroll !important;
    }

    .modal_footer {
      border: none;

      button {
        border: none;
        padding: 0.5rem 2.3rem;
        color: white;
        font-weight: bold;

        &:first-child {
          background: #4d99ff;
        }

        &:last-child {
          background: grey;
        }
      }
    }
  }
}


.file_manager_sftp_modal {
  .modal-content {
    min-width: 600px;
    max-width: 800px;

    .modal-header {
      border: none;
      background-color: var(--dark-bg-color2);
      color: white;
    }

    .modal-body {
      color: white;
      background-color: var(--dark-bg-color1);

      .sftp_info_wrapper {
        padding: 1rem;

        .sftp_access_row {
          word-break: break-word;
          display: flex;
          flex-direction: column;

          .sftp_pwd_wrapper {
            svg {
              cursor: pointer;
            }
          }

          span {
            font-weight: bold;
            padding: 0.3rem 0.1rem;
            font-size: 0.9rem;

            &:nth-child(2n) {
              background-color: var(--dark-bg-color2);
            }
          }
        }

        .close_btn_wrapper {
          width: 100%;
          text-align: right;

          button {
            border: 1px solid white;
            background: none;
            color: white;
            padding: 0.6rem 2rem;
          }
        }

        .sftp_description {
          display: flex;
          flex-direction: column;

          span {
            margin-top: 0.6rem;
            font-size: 0.875rem;
          }
        }
      }
    }
  }
}
