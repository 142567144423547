.setting_list_wrapper {
  padding: 1rem;
  min-height: 400px;

  svg {
    margin-left: 0.5rem;
  }

  .setting_list_host_wrapper {
    label {
      font-weight: bold;
      color: var(--bright-txt-color);
      padding: 1rem 0;
    }

    #setting_label, #setting_hostName {
      padding: 1rem;
    }

  }

  .setting_additional {
    padding: 2rem;

    span {
      font-weight: bold;
    }

    .provide_option_wrapper {
      margin-top: 1rem;

      .provide_option_item {
        margin-top: 1rem;
        display: flex;
        flex-direction: row;
        align-items: center;

        .option_form, {
          min-width: 140px;
        }
      }
    }
  }

  .setting_additional, .setting_scripts {
    min-height: 230px;
  }

  .setting_scripts {
    margin-top: 2rem;
    .script_list_wrapper {
      .script_item {
        position: relative;
        padding: 1rem;
        border-radius: 12px;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 0.7rem;
        cursor: pointer;
        background-color: var(--bright-bg-color);

        &:hover {
          border: 1px solid rgba(65, 117, 255, 0.44);
        }

        img {
          width: 50px;
        }

        span {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          font-weight: bold;
        }

        span, small {
          margin-left: 1rem;
        }
      }
    }

    .not_exist_script_deploy {
      margin-top: 2rem;
      padding: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
      border-radius: 15px;

      span {
        color: #5e5e5e;
      }

      button {
        margin-top: 2rem;
        border: none;
        padding: 1rem 2.5rem;
        background-color: white;
        color: #4D99FF;
        border-radius: 30px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

        &:hover {
          background-color: #4D99FF;
          color: white;
        }
      }
    }
  }


}
.last_price_wrapper {
  display: flex;
  flex-direction: row;
  padding-top: 2rem;
  justify-content: space-between;
  .summary_price_wrapper {
    #total_price {
      display: flex;
      flex-direction: row;
      align-items: flex-end;

      h3 {
        color: #0a77d5;
        margin: 0;
      }
    }
  }
}
@media (max-width: 800px) {
  .setting_ip_wrapper {
    padding: 0 !important;

    .mobile_ipv4_table {
      margin-top: 1.5rem;
      display: flex;
      flex-direction: column;

      .mobile_ipv4_item {
        padding: 1rem;
        border: 1px solid rgba(0, 0, 0, 0.15);

        .ip_key {
          color: #8592AF;
        }

        span {
          font-weight: bold;
          font-size: 0.8rem;
          margin-top: 0.4rem;
        }

        .main_ip_item {
          display: flex;
          flex-direction: row;
          align-items: center;

          .main_ip_chip {
            margin-top: 0.4rem;

            span {
              margin: 0 !important;
              font-size: 0.5rem;
            }
          }
        }
      }
    }
  }
  .last_price_wrapper{
    padding: 0;
    .deploy_term_wrapper{
      padding: 0.3rem 0;
      small{
        font-size: 0.7rem;
      }
    }
    .game_deploy_qty_wrapper{
      width: 100%;
      margin-right: 0;
      justify-content: flex-end;
      button {
        width: 40px!important;
        height: 40px!important;
      }
    }
  }
  .summary_price_wrapper{
    margin-left: 0!important;
    flex-direction: row!important;
    align-items: center;
    #total_price{
      margin-top: 0!important;
      margin-left: 1rem;
    }
  }
}

@media (max-width: 480px) {
  .mobile_ipv4_item {
    justify-content: space-between;
  }
}
