.game_deploy_warning_wrapper {
  .warning_txt {
    height: 100%;
    text-align: left;
    padding: 1rem;
    background-color: var(--dark-bg-color2);
    border-radius: 12px;
    font-size: 0.8rem;
    display: flex;
    flex-direction: column;

    span {
      margin-top: 0.3rem;
    }
  }

  .deploy_agreement_label {
    span {
      &:last-child {
        color: #4d99ff !important;
      }
    }
  }
}

.game_deploy_box {
  .deploy_footer {
    background-color: var(--dark-bg-color2);
    box-shadow: rgb(0 134 255 / 48%) 6px 2px 16px 0px, rgb(54 54 54 / 80%) -6px -2px 16px 0px;

    h4, h3, small {
      color: white !important;
    }

    .server_deploy_btn_wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;

      .game_deploy_qty_wrapper {
        display: flex;
        flex-direction: row;
        margin-right: 3rem;

        .qty_btn, .qty_num {
          font-weight: bold;
          border: 1px solid grey;
          width: 50px;
          height: 50px;
          color: white;
          background-color: var(--dark-bg-color2);
        }
      }
    }

    .deploy_term_wrapper {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      small {
        font-weight: bold;

        &:first-child {
          margin-right: 0.7rem;
        }
      }
    }
  }
}

.game_deploy_wrapper {
  min-height: 100vh;

  .game_selection_wrapper {

    .game_selection {

      .game_select_item {
        margin-top: 1rem;
        border-radius: 20px;
        min-height: 172px;
        background-size: cover;
        background-repeat: no-repeat;

        .filter_bg {
          background-color: rgba(0, 0, 0, 0.64);
          width: 100%;
          height: 172px;
          position: relative;
          border-radius: 20px;
          cursor: pointer;

          &.del_filter {
            border: 3px solid #4d99ff;
            background-color: rgba(0, 0, 0, 0.14) !important;
          }

          &:hover {
            background-color: rgba(0, 0, 0, 0.14);
          }

          h4 {
            width: 100%;
            text-align: center;
            font-weight: bold;
            color: white;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }

          .coming_soon_txt {
            color: white;
            font-weight: bold;
            position: absolute;
            top: 70%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
  }

  .minecraft_select_price_wrapper {
    padding-bottom: 3rem;

    .minecraft_price_selection {

      .mine_price_item {
        margin-top: 4rem;
        position: relative;
        background-color: var(--dark-bg-color2);
        border-radius: 20px;
        border: 3px solid var(--dark-bg-color2);
        cursor: pointer;

        &.select {
          border-color: grey;
        }

        &:hover {
          border-color: grey;
        }

        .mine_price_block {
          position: absolute;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        .price_info {
          padding: 4rem 0 0 0;
          text-align: center;
          color: white;

          .monthly_price {
            padding: 1rem 0;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: flex-end;
          }

          h4, h3, small {
            font-weight: bold;
          }
        }

        hr {
          margin: 1rem auto;
          height: 3px;
          color: #fff;
          width: 80%;
        }

        .price_performance {
          padding-bottom: 1rem;
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          span {
            margin-top: 0.4rem;
          }
        }

      }

    }

  }


  .minecraft_service_section {
    .option_chip_wrapper {
      text-align: right;
    }

    #setting_label {
      background-color: #404040;
      color: white;
    }

    svg {
      fill: white;
    }

    label, span {
      color: white !important;
    }
  }

}

.game_deploy_region_wrapper {
  &.deploy_region_list {
    padding: 1rem;

    .server_region {
      position: relative;

      .deploy_region_item {
        margin-top: 1rem;
        cursor: pointer;
        transition-duration: 0.5s;
        box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        padding: 0.7rem;
        border: 1px solid #afafaf;
        position: relative;

        &:hover {
          border: 1px solid #4D99FF;
        }

        #deploy_region_item_loader {
          color: #4d99ff;
        }
      }

      .region_country {
        margin-left: 0.6rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        span, small {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        span {
          font-weight: bold;
          display: block;
        }

        small {
          color: #939393;
        }
      }

      .region_img {
        img {
          max-width: 50px;
        }
      }

      &.select_region {
        .deploy_region_item {
          border: 1px solid #4D99FF;
        }
      }
    }
  }

  .game_deploy_region_alert {
    padding: 2rem 0;
  }
}

.mine_game_type_wrapper {
  .game_type_row {
    flex-direction: row;

    .game_type_item {
      border-radius: 20px;
      background: none;
      align-items: center;
      justify-content: center;
      border: 3px solid var(--dark-bg-color2);
      cursor: pointer;

      &:hover {
        border-color: grey;
      }

      &.select_type {
        border-color: grey;
      }

      .col-12 {
        padding: 0 !important;
      }

      .game_type_img {
        border-radius: 20px;
        background-size: cover;
        background-repeat: no-repeat;
        min-width: 100%;
        min-height: 220px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

      }

      .game_type_txt {
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        background-color: rgba(0, 0, 0, 0.48);
        padding: 1rem 0;

        h4 {
          text-align: center;
          font-weight: bold;
          color: white;
        }
      }
    }
  }
}

.mine_deploy_loader_wrapper {
  background-color: var(--dark-bg-color2);
  width: 100%;
  height: 100vh;
  position: relative;

  .loader_content {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      max-width: 250px;
    }

    span {
      font-size: 2rem;
      font-weight: bold;
      color: white;
      display: inline-block;
      margin: 2rem -.05em 0;
      animation: loading .7s infinite;


    }


    @keyframes loading {
      0%, 100% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(15px);
      }
    }
  }
}

.game_main_domain_form {
  #game_main_domain {
    padding: 0;
  }
  #game_main_domain_select{
    color: white;
    background-color: #404040;

  }
  fieldset{
    border-color: white;
  }
}


@media (max-width: 880px) {
  .minecraft_service_section {
    .setting_additional {
      padding: 2rem 0;
    }

    .provide_option_item {
      flex-direction: column !important;
      justify-content: space-between !important;
      align-items: normal !important;
    }

  }
}
