.price_list_wrapper {
  padding: 1rem;

  .price_list_row {
    justify-content: flex-start;

    .plan_item_wrapper {
      margin-top: 1rem;
      cursor: pointer;

      .plan_item {
        border-radius: 20px;
        border: 1px solid rgba(0, 0, 0, 0.12);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1rem 0;
        position: relative;
        background-color: #F8FAFB;

        &:hover {
          border: 1px solid rgba(65, 117, 255, 0.44);
        }

        .plan_performance_item {
          min-height: 20px;
          width: 100%;
          text-align: left;
          margin-top: 0.5rem;

          img {
            max-width: 20px;
          }

          span {
            margin-left: 1rem;
          }
        }


        #common_price {
          color: black;
        }

        #event_price, #event_hour_price {
          font-weight: bold;
          color: #0a77d5;
          padding: 1rem 0;
        }


        span, h4 {
          padding: 0.3rem 0;
        }


        hr {
          height: 3px;
          width: 80%;
          color: #0a77d5;
        }

        .plan_server_detail {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          font-size: 0.8rem;
        }

        &.select_plan {
          background-color: #4D99FF;
          //background-image: radial-gradient(circle farthest-corner at 10% 20%, rgba(37, 145, 251, 0.98) 0.1%, rgb(46, 57, 217) 99.8%);
          color: white;


          #event_price, #event_hour_price {
            color: white;
          }

          .plan_performance_item {
            img {
              -webkit-filter: opacity(1) drop-shadow(0 0 0 white);
              filter: opacity(1) drop-shadow(0 0 0 white);
            }
          }

          #common_price {
            color: white;
          }

          h4 {
            color: white;
          }

          hr {
            color: white;
          }
        }
      }
    }
  }
}
