.new_phone_modal {
  max-width: 600px !important;

  .modal-header {
    display: block !important;

    .phone_modal_title {
      padding: 1rem;

      img {
        width: 70%;
      }
    }
  }

  .modal_title {
    width: 100%;
    color: #90A2B2;
  }

  .phone_modal_auth_wrapper {
    padding: 2rem 2rem;

    .phone_modal_auth {

    }
  }

  #phone_auth_count {
    color: #4D99FF;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    right: 0;
  }

  #new_phone_form {
    .send_msg_btn {
      margin-left: 1rem;
      min-width: 113px;
    }

    .new_phone_input {
      width: 100%;
    }


  }

}

.account_wrapper {
  padding: 2rem 0;

  &.dark_mode_account {
    .account_tab_wrapper {
      button {
        color: white;

        &.Mui-selected {
          color: #4d99ff !important;
        }
      }
    }

    .auth_business_wrapper {
      background-color: #404040;

      h4, label, input {
        color: white !important;
      }

      input, .auth_business_description {
        background-color: var(--dark-bg-color2);
      }
    }

    .profile_wrapper {
      background-color: #404040;

      h4, label, input {
        color: white !important;
      }

      input, .my_input_wrapper {
        background-color: var(--dark-bg-color2);
      }
    }

    .MuiChip-label {
      color: grey;
    }

    .history_table_wrapper {
      .dark_table_mode {
        th, td {
          color: white !important;
        }
      }
    }


  }

  .account_top {
    padding: 2rem 0;

    h2 {
      font-weight: bold;
    }
  }

  .profile_wrapper {
    margin: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    background-color: white;
    padding: 2rem;
    border-radius: 15px;

    h3 {
      font-weight: bold;
    }


    .profile_left_form {
      .profile_detail {
        display: flex;
        flex-direction: column;
        width: 80%;
        padding: 2rem 0;

        .MuiFormControl-root {
          margin-bottom: 0.5rem;
        }

        input {
          padding: 0.6rem 0.8rem;
        }

        label {
          color: #90A2B2;
          padding: 1rem 0;
        }

        #phone_btn {
          margin-top: 1rem;
          padding: 0.7rem 0;
          width: 100%;
          color: white;

          &:hover {
            background-color: #59a2e2;
          }
        }

        input {
          z-index: 99;
          color: #282828;
        }

        fieldset {
          background-color: #EEEEEE;
        }

      }
    }

    .profile_right_form {
      .change_password_form {
        padding-top: 2rem;
        width: 80%;

        .change_password_submit_btn {
          .change_password_btn {
            padding: 0.7rem 0;
            width: 100%;
            color: white;

            &:hover {
              background-color: #59a2e2;
            }
          }
        }
      }
    }

    .marketing_agree_wrapper {
      margin-top: 1rem;

      .marketing_sms {
        margin-top: 1rem;
      }

      .marketing_sms, .marketing_email {
        max-width: 395px;
        padding: 1rem 0;

        span {
          font-weight: bold;
          font-size: 1rem;
        }

        .marketing_description {
          color: var(--bright-txt-color);
          margin-top: 1rem;
        }
      }
    }
  }

  .activity_history_wrapper {
    h4 {
      padding: 2rem 0;
    }

    .search_filter_header_wrapper {
      display: flex;
      flex-direction: column;

      .search_filter_header {
        background-color: white;
        padding: 0.5rem 0;
        display: flex;
        flex-direction: row;
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 5px;

        .filter_date, .filter_type, .filter_txt {
          flex: 1;
          padding: 0.5rem 2rem;
          display: flex;
          flex-direction: row;
          align-items: center;

          span {
            flex: 1;
            font-size: 1.1rem;
          }

          .search_filter_item {
            flex: 3;
          }
        }

        .filter_date, .filter_txt {
          .MuiTextField-root fieldset {
            border-radius: 5px;
          }
        }
      }

      .search_filter_btn_wrapper {
        width: 100%;
        background-color: #f1f1f1;

        .search_btn {
          padding: 0.5rem 2rem;
          text-align: right;

          button {
            padding: 0.5rem 2rem;
          }
        }
      }
    }

    .history_table_wrapper {
      margin-top: 2rem;

      .not_exist_activity_table {
        text-align: center;
        padding: 3rem 0;
      }
    }

  }
}

.auth_business_wrapper {
  margin-top: 3rem;
  background-color: white;
  border-radius: 15px;
  padding: 2rem;

  .auth_business_toast, .auth_business_form, .auth_business_promotion {
    flex: 1;
  }

  .auth_business_form {
    max-width: 500px;

    h3 {
      font-weight: bold;
    }

    input {
      padding: 0.6rem 0.5rem;
    }

    label {
      color: #666f7b;
      padding: 1rem 0;
    }

    .auth_business_form_item {
      margin-top: 2rem;

      .auth_business_btn {
        margin-top: 1rem;
        padding: 0.7rem 0;
      }
    }
  }

  .auth_business_promotion {
    padding: 1rem;
  }

  .auth_business_description {
    width: 100%;
    background-color: var(--bright-bg-color);
    border-radius: 12px;
    min-height: 150px;

    .business_txt {
      margin-top: 1rem;
      padding: 2rem 1rem;
    }
  }
}


@media (max-width: 880px) {
  .profile_detail {
    width: 100% !important;
    padding: 1rem 0 !important;
  }
  .change_password_form {
    width: 100% !important;
  }
  .profile_right_form {
    margin-top: 2rem !important;
  }
  .auth_business_wrapper {
    padding: 1rem !important;

    .auth_business_description {
      span {
        font-size: 0.8rem;
      }
    }
  }
}

