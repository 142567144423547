.deploy_wrapper {
  height: 100%;

  .steper_wrapper {
    background-color: white;
    padding: 1rem;
  }

  .deploy_header {
    padding: 2rem 0;

    h3 {
      font-weight: bold;
    }
  }

  .deploy_content {
    padding: 0 32px 3rem 32px;
    margin: 1rem 0;
    min-height: 500px;
    background-color: white;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .select_service_title {
      padding: 2rem 0;
      text-align: center;

      h4 {
        font-weight: bold;
        color: #4D99FF;
      }
    }

    .deploy_service_list {
      //display: flex;
      //flex-direction: row;
      //justify-content: space-around;
      padding: 2rem 0;

      .loader_img_loader {
        color: #1976d2 !important;
        width: 130px !important;
        height: 130px !important;
      }

      .server_service {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
        transition-duration: 1s;
        cursor: pointer;
        border-radius: 100%;
        position: relative;

        svg {
          right: 5%;
          top: 2%;
          position: absolute;
          font-size: 3rem;
          color: #4D99FF;
          z-index: 999;
          background: white;
          border-radius: 100%;
        }

        button {
          min-height: 172px;
          border-radius: 15px;
          width: 100%;
          border: 3px solid #eaeaea;
          position: relative;
          background-color: white;

          &:hover {
            border: 3px solid #4D99FF;
          }

          img {
            padding: 2rem 0;
          }
        }


        &.select_service {
          button {
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
            border: 3px solid #4D99FF;

          }
        }


        img {
          transition-duration: 1s;
          width: 100px;
        }

        span {
          margin-top: 1rem;
          color: #383838;
          font-weight: bold;
          font-size: 1.2rem;
        }
      }

      .select_service_description {
        padding: 2rem 0;
      }
    }

    .deploy_region_list {
      padding: 1rem;


      .server_region {
        position: relative;

        .deploy_region_item {
          margin-top: 1rem;
          cursor: pointer;
          transition-duration: 0.5s;
          box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 100%;
          padding: 0.7rem;
          border: 1px solid rgba(0, 0, 0, 0.02);
          position: relative;

          &:hover {
            border: 1px solid #4D99FF;
          }

          #deploy_region_item_loader {
            color: #4d99ff;
          }
        }

        .region_country {
          margin-left: 0.6rem;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;

          span, small {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          span {
            font-weight: bold;
            display: block;
          }

          small {
            color: #939393;
          }
        }

        .region_img {
          img {
            max-width: 50px;
          }
        }

        &.select_region {
          .deploy_region_item {
            border: 1px solid #4D99FF;
          }
        }
      }
    }

    .deploy_os_wrapper {
      .tab_panel {
        padding: 0;
        min-height: 400px;
      }
    }

    .sf_list_wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 1rem;
      justify-content: flex-start;


      .sf_item {
        cursor: pointer;
        transition-duration: 0.5s;
        margin-top: 0.7rem;

        .sf_content {
          position: relative;
          padding: 1rem;
          border: 1px solid rgba(0, 0, 0, 0.02);
          box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
          display: flex;
          flex-direction: row;
          width: 100%;
          height: 100%;

          &:hover {
            border: 1px solid #4D99FF;
          }

          .sf_img {
            img {
              max-width: 36px;
            }
          }
        }

        .sf_version {
          margin-left: 0.8rem;
          width: 100%;

          #os_name {
            display: block;
            font-weight: bold;
          }

          small {
            color: #939393;
          }
        }

        &.select_item {
          .sf_content {
            border: 1px solid #4D99FF;
          }
        }
      }
    }


  }

  .os_list_item_wrapper {
    padding: 1rem;

    .os_item {
      cursor: pointer;
      height: 100%;

      &.select_item {
        .os_content {
          position: relative;
          //background-image: radial-gradient(circle farthest-corner at 10% 20%, rgba(37, 145, 251, 0.98) 0.1%, rgb(46, 57, 217) 99.8%);
          //color: white;

          //#os_item_atr, #os_item_date {
          //  color: white;
          //}

        }
      }

      .os_content {
        box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
        padding: 1rem;
        border: 1px solid rgba(0, 0, 0, 0.02);
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 0.7rem;

        &:hover {
          border: 1px solid rgba(65, 117, 255, 0.44);
        }

        .os_img {
          img {
            max-width: 36px;
          }
        }

        .os_description {
          margin-left: 1rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          #os_item_name {
            font-size: 1rem;
            font-weight: bold;
          }

          small {
            display: block;
            color: #8d8d8d;
          }

        }
      }
    }
  }
}

.deploy_title {
  padding: 2rem 0 !important;

  span {
    font-weight: bold;
    font-size: 24px;
  }
}

.server_type_list_wrapper {
  position: relative;

  .server_type_name {
    margin-top: 1rem;
    color: #383838;
    font-weight: bold;
    font-size: 1.2rem;
  }

  .server_type_item {
    border-radius: 15px;
    position: relative;
    min-height: 172px;
    border: 3px solid #eaeaea;
    padding: 1rem;
    text-align: center;

    #server_cPanel_logo {
      width: 100%;
      max-width: 200px;
    }

    #server_hosting_logo {
      max-width: 100px;
    }

    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    #server_game_logo{
      max-width: 130px;
    }
    &:hover {
      border: 3px solid #4D99FF;
    }

    &.select_item {
      border: 3px solid #4D99FF;

      svg {
        right: 3%;
        top: 2%;
        position: absolute;
        font-size: 3rem;
        color: #4D99FF;
        z-index: 999;
        background: white;
        border-radius: 100%;
      }
    }
  }
}

.service_policy_box {
  .warning_deploy_wrapper {
    height: 100%;
    text-align: left;
    padding: 1rem;
    background-color: var(--bright-bg-color);
    border-radius: 12px;
    font-size: 0.8rem;

    span {
      color: var(--bright-txt-color);
    }
  }
  .deploy_agreement_wrapper{
    padding: 0.3rem;
    color: #4d99ff;
  }
}

.deploy_footer {
  z-index: 999;
  box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
  width: 100%;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;

  .last_step_footer {
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;


    .last_price_wrapper {
      padding: 1rem;
      text-align: left;

      .summary_price_wrapper {
        margin-left: 1.5rem;
        display: flex;
        flex-direction: column;

        #total_price {
          margin-top: 0.5rem;
          display: flex;
          flex-direction: row;

          h3 {
            font-weight: bold;
            color: #0a77d5;
          }

          small {
            font-weight: bold;
            color: var(--bright-txt-color);
          }

          .deploy_month_price, .deploy_month_price {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
          }

          .deploy_month_price {
            font-weight: bold;
          }
        }
      }

      .deploy_btn_wrapper {
        margin-top: 1rem;
      }
    }
  }

  .server_deploy_btn_wrapper {
    text-align: center;
    position: relative;

    #deploy_next_btn {
      padding: 1.2rem 5rem;
      border-radius: 5px;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      font-weight: bold;
      background: #4D99FF;
      color: white;
      &.Mui-disabled{
        background: rgba(128, 128, 128, 0.47) !important;
      }
    }

    #deploy_back_btn {
      position: absolute;
      left: 0;
      float: left;
      padding: 1.2rem 2rem;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }
  }


}


@media (max-width: 880px) {
  .deploy_content {
    padding: 0 5px 3rem 5px !important;
  }
  .server_service {
    margin-top: 1rem !important;
    padding: 0 !important;
  }
  .deploy_service_list {
    .select_service {
      button {
        border-radius: 15px !important;
        border: 3px solid #4D99FF !important;
      }

      svg {
        display: none;
      }
    }

    .spin {
      margin-top: 1rem;
      max-height: 100px;
      width: 90% !important;
      border-radius: 0 !important;

      &::before, &::after {
        border: none !important;
        border-radius: 15px !important;
      }

      img {
        position: absolute;
        width: 40% !important;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }

    span {
      font-size: 0.8rem !important;
    }

  }


  .sf_list_wrapper {
    padding: 0 !important;
  }
  .price_list_wrapper {
    padding: 0 !important;
  }
  .plan_performance_item {
    img {
      display: none;
    }
  }

  .steper_wrapper {
    span {
      font-size: 0.6rem;
    }
  }
  .select_service_description {
    padding: 4rem 0 0 0 !important;
  }
  .warning_deploy_wrapper {
    font-size: 0.8rem;
  }
  .deploy_footer {
    padding: 0 !important;

    .last_price_wrapper {
      flex-direction: column !important;
    }

    .server_deploy_btn_wrapper {
      flex-direction: column!important;
    }

    #deploy_next_btn {
      display: block;
      margin-top: 1rem;
      width: 100%;
      padding: 0.8rem !important;
    }

    .summary_price_wrapper {
      h4 {
        font-size: 1rem;
      }
    }
  }
  .deploy_title {
    span {
      font-size: 20px;
    }
  }
}
