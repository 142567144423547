.game_manage_tab_wrapper {
  background-color: var(--dark-bg-color3);

  .game_tab_box {
    min-height: 400px;
    position: relative;
    height: 100%;

    .game_tab_change_loader {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      .MuiCircularProgress-root {
        width: 50px!important;
        height: 50px!important;
      }

      svg {
        color: #4d99ff;
      }
    }
  }

  .game_setting_tab {
    button {
      color: white;

      &.Mui-selected {
        color: #4d99ff !important;
      }
    }
  }

  .setting_panel_wrapper {
    .setting_panel_item {
      min-height: 220px;
      margin-top: 1rem;
      background-color: var(--dark-bg-color2);
      padding: 1rem;

      .setting_description {
        span {
          font-weight: bold;
          margin-bottom: 0.5rem;
        }

        span, p {
          display: block;
        }

        p {
          min-height: 30px;
          font-size: 0.7rem;
        }
      }

      .setting_form {
        padding-top: 0.875rem;

        .game_setting_select {
          background-color: var(--dark-bg-color1);

          svg {
            fill: white;
          }
        }

        .MuiInputBase-root {
          width: 100%;
        }

        select {
          color: white;
          width: 100%;
        }

        .basic_setting_input {
          min-height: 58px;
          border: 1px solid white;
          background-color: var(--dark-bg-color1);
          width: 100%;
          color: white;
          padding: 0.3rem 0.5rem;
        }
      }
      .game_setting_action{
        text-align: left;
        margin-top: 1rem;
      }
    }
  }


}
