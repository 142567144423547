.billing_wrapper {
  padding: 0;

  .Mui-selected {
    color: #4d99ff !important;
  }

  .box_title {
    font-size: 1.2rem;
    font-weight: bold;
  }

  .billing_title {
    padding: 3rem 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    h2 {
      font-weight: bold;
    }

    .holding_point_wrapper {
      padding: 0 2rem;
      color: #0a77d5;

      h3, h2 {
        font-weight: bold;
      }

      h2 {
        margin-top: 1rem !important;
      }
    }
  }

  .billing_info_container {
    padding: 2rem 0;
    justify-content: space-between;

    .billing_left_wrapper {
      .billing_info_wrapper {
        float: left;
      }
    }

    .billing_right_wrapper {
      .billing_info_wrapper {
        float: right;
      }
    }

    .billing_info_wrapper {
      width: 95%;
      justify-content: space-between;

      .bill_box_wrapper {
        border-radius: 10px;
        background-color: white;
        padding: 1rem;
        margin-bottom: 2rem;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

        .bill_box_title {
          margin-bottom: 1.5rem;
        }

        .bill_box_row {
          display: flex;
          flex-direction: column;

          .bill_box_item {
            padding: 0.5rem 0;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            span {
              &:first-child {
                color: #8297AA;
              }
            }
          }
        }
      }
    }
  }


  table, thead, tbody, tr {
    border-bottom: none;
  }

  th, td {
    border-bottom: none;
  }

  .billing_charge_wrapper {
    .charge_title {
      padding: 2rem 1rem;
    }
  }

  .charging_point_wrapper {
    background-color: white;
    padding: 2rem 1rem;

    .charging_point_price {
      .point_price_label {
        min-width: 100px;
      }

      .point_price_wrapper {
        padding: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .dark_type {
          .point_price_item {
            background-color: var(--dark-bg-color2);

            h3 {
              color: white;
            }
          }
        }

        .point_price_item {
          margin-top: 0.5rem;
          cursor: pointer;
          border: 3px solid rgba(0, 0, 0, 0.18);
          padding: 0.5rem;
          border-radius: 5px;

          &.select_price {
            border-color: #0a77d5;
            color: #0a77d5;
          }

          .top_point {
            padding: 1rem 1rem;
            border-bottom: 1px solid #4D99FF;
          }

          .bottom_price {
            padding: 1rem;
          }
        }

      }
    }

    .charging_point_type {
      margin-top: 2rem;

      .charging_point_type_item {
        margin-top: 0.6rem;
        text-align: center;
        padding: 1rem;
        border: 3px solid rgba(0, 0, 0, 0.18);
        cursor: pointer;
        border-radius: 5px;

        &.select_type {
          border-color: #0a77d5;
          color: #0a77d5;
        }

        .type_img {
          img {
            width: 50px;
          }

          .gray_img {
            -webkit-filter: opacity(.5) drop-shadow(0 0 0 gray);
            filter: opacity(.5) drop-shadow(0 0 0 gray);
          }
        }
      }
    }

    .payment_policy_wrapper {
      margin-top: 2rem;

      .policy_txt_wrapper {
        min-height: 200px;
        background-color: rgba(245, 245, 245, 0.58);
        border-radius: 5px;

        .policy_txt {
          padding: 1rem;
          color: rgba(0, 0, 0, 0.65);
          font-size: 0.7rem;
        }
      }

      .agreement_checkbox {
        padding: 1rem 2rem;
      }
    }

    .charging_point_btn {
      text-align: left;

      button {
        padding: 0.8rem 3.5rem;
      }
    }

    label {
      font-weight: bold;
      color: #8297AA;
      padding: 0.5rem 0;
    }

    input {
      padding: 0.7rem 10px;
    }

    button {
      margin: 2rem 0;
      font-size: 1.1rem;
      font-weight: bold;
      padding: 0.8rem 0;
    }

    .plus_point_btn {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      button {
        width: 22%;
      }
    }

    .charging_point_input {
      position: relative;

    }

    &.dark_bg_color1 {
      .charging_point_type {
        .charging_point_type_item{
          background-color: var(--dark-bg-color2);
          .gray_img{
            filter: opacity(0.5) drop-shadow(0 0 0 white) !important;
          }
        }
      }
      .policy_txt_wrapper{
        background-color: var(--dark-bg-color2) !important;
      }
      .charging_point_btn{
        button{
          color: white;
        }
      }
    }
  }

  .billing_list_wrapper {
    background-color: white;
    margin-top: 2rem;
    border-radius: 10px;
    box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
    padding: 1.5rem;

    .billing_list_title {
      padding: 1rem;
      font-size: 1.2rem;
      font-weight: bold;
    }
  }

  .billing_info_wrapper {
    padding: 1rem 0;
  }

  .point_usage_wrapper {
    background-color: white;
    &.dark_bg_color1{
      .billing_list_wrapper{
        background-color: var(--dark-bg-color1) !important;
        box-shadow: none;
      }
    }
  }


}

.payment_request_wrapper {

  min-height: calc(100vh - 200px);
  position: relative;

  .success_pay_wrapper {
    background-color: white;
    padding: 3rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .pay_btn_wrapper {
      margin-top: 1rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .pay_btn_item {
        padding: 0.5rem;
        width: 45%;
      }
    }

    .success_bill_title {
      text-align: center;

      h4 {
        color: #0a77d5;
        padding-bottom: 1rem;
      }

      span {
        color: #8297AA;
      }
    }

    .success_bill {
      border: 3px solid #8297AA;
      margin-top: 1rem;
      display: flex;
      flex-direction: row;
      padding: 2rem;
      justify-content: space-between;

      .bill_item {
        display: flex;
        flex-direction: column;

        span {
          padding: 0.5rem 0;
          border-bottom: 1px solid rgba(0, 0, 0, 0.25);
        }

        &:first-child {
          color: #8297AA;
        }

        &:last-child {
          text-align: right;
          font-weight: bold;

          span {
            padding-left: 1rem;
          }
        }
      }
    }

  }


  .payment_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h3 {
      padding: 2rem 0;
    }
  }
}

.charging_receipturl_modal {
  .modal-body {
    padding: 0;
  }

  .charging_receipturl_wrapper {
    background-color: white;
    display: flex;
    flex-direction: column;
    padding: 2rem 1.5rem;

    &:after {
      content: "";
      position: absolute;
      display: block;
      height: 10px;
      bottom: -10px;
      left: 0;
      right: 0;
      background: linear-gradient(45deg, transparent 33.333%, white 33.333%, white 66.667%, transparent 66.667%), linear-gradient(-45deg, transparent 33.333%, white 33.333%, white 66.667%, transparent 66.667%);
      background-size: 8px 20px;
      background-position: 0 -10px;
    }


    .point_title {
      padding: 1rem;
      text-align: center;
      border-bottom: 3px solid rgba(0, 0, 0, 0.31);
    }

    section {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px dotted rgba(0, 0, 0, 0.31);
      padding: 0.5rem 0;

      .pay_type {
        color: rgba(0, 0, 0, 0.31);
      }

      .pay_info {
        text-align: right;
      }

      .pay_type, .pay_info {
        display: flex;
        flex-direction: column;

        span {
          font-weight: bold;
          padding: 0.3rem 0;
        }

        #pay_info_date {
          padding: 0.7rem 0;
        }
      }
    }
  }

  .modal-content {
    border: none;
    background-color: rgba(0, 0, 0, 0);
  }

  .modal-footer {
    border: none;
    text-align: center;
    justify-content: center !important;

    button {
      margin-top: 1rem;
      background: none;
      border-radius: 50px;
      color: white;
      border-color: white;
      padding: 0.4rem 2.2rem;
      border-style: solid;
    }
  }
}

.small_font {
  font-size: 0.8rem;
  color: rgba(0, 0, 0, 0.37);
}

@media (max-width: 880px) {
  .billing_info_wrapper {
    width: 100% !important;
    padding: 1rem !important;

    .bill_box_item {
      span {
        font-size: 0.8rem;

        &:last-child {
          text-align: right;
        }
      }
    }
  }

  .charging_receipturl_modal {
    .charging_receipturl_wrapper {
      padding: 0 1.5rem !important;
    }

    span {
      font-size: 0.9rem;
    }
  }

  .charging_point_wrapper {
    .point_price_wrapper {
      justify-content: center !important;

      .bill_point_type {
        margin-top: 1rem;

      }

      .bill_point_type, .bill_pay_type {
        span {
          font-size: 0.8rem;
        }
      }
    }


    .bill_check_label {
      padding: 0.5rem 0.8rem !important;
      margin-top: 1rem;
      margin-right: 0 !important;

      span {
        font-size: 0.8rem;
      }
    }
  }
  .payment_wrapper {
    h4 {
      font-size: 0.8rem !important;
    }
  }
  .charging_point_wrapper {
    padding: 2rem 0.75rem !important;
  }

  .billing_list_wrapper {
    padding: 0 !important;
  }
  .mobile_point_usage_table {
    span {
      font-size: 0.8rem;
    }
  }
  .charging_point_btn {
    button {
      width: 100%;
    }
  }
}
