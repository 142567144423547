.my_input_group {
  padding: 1rem 0;
  label {
    color: #acacac;
    font-size: 0.9rem;
  }
  .my_input_wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 2px solid #d0d0d0;

    img {
      width: 20px;
      height: 20px;
      margin-right: 1rem;
      filter: invert(97%) sepia(0%) saturate(1247%) hue-rotate(261deg) brightness(83%) contrast(72%);
    }

    input {
      padding: 0.7rem 0;
      border: none;

      &:focus {
        border: none;
        box-shadow: none;
      }

      &::placeholder {
        color: #B8B8B8;
      }
    }
  }
}
