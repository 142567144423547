.clause_wrapper {
  .clause_box {
    padding: 1rem;

    .clause_txt {
      max-height: 150px;
      overflow: auto;
      background-color: #f3f3f3;
      padding: 0.5rem;
      span{
        font-size: 0.9rem;
      }
      p {
        font-size: 0.8rem;
      }
      &.marketing_clause{
        padding: 0.5rem;
      }
      table{
        border-top: 2px solid #555555;
        td{
          &:nth-child(1),&:nth-child(2){
            border-right: 1px solid rgba(0, 0, 0, 0.13);
          }
        }
        thead{
          td{
            background-color: #F9FAFC;
            padding: 0.4rem 0.4rem;
          }
        }
        tbody{
          td{
            border-top: 1px solid rgba(0, 0, 0, 0.13);
            background-color: white;
            padding: 0.4rem 0.2rem;
          }
        }
      }
    }

    .all_check_btn {
      span {
        font-size: 0.9rem;
      }
    }
    .marketing_clause_item{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      span{
        font-weight: bold;
        font-size: 0.875rem;
      }
      small {
        color: #0a53be;
      }
    }
    .clause_item {
      padding: 0.3rem 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      span {
        font-weight: bold;
      }

      small {
        color: #0a53be;
      }
    }

    .use_clause_wrapper, .my_clause_wrapper {
      margin-top: 1rem;
    }

    .clause_btn_wrapper {
      padding: 1rem 0;
      display: flex;
      justify-content: space-between;
      width: 100%;

      button {
        padding: 0.7rem 0;
        width: 48%;

        span {
          font-size: 1.1rem;
          font-weight: bold;
        }
      }
    }
  }
}


.register_wrapper {
  .register_box {
    padding: 3rem;

    h2 {
      text-align: center;
    }

    .register_phone_wrapper {
      .certi_btn {
        width: 25%;
        max-height: 40px;

        button {
          background-color: #73B1E7FF;
          border-color: #73B1E7FF;
        }
      }
    }

    .register_btn {
      width: 100%;
      border-radius: 20px;
      padding: 0.5rem 0;
      background-color: #73B1E7FF;
      border-color: #73B1E7FF;
      color: white;
    }

    .auth_phone_btn {
      border-radius: 20px;
      padding: 0.5rem 0;
      background-color: #73B1E7FF;
      border-color: #73B1E7FF;
      width: 25%;
      max-height: 40px;
      margin-right: 1rem;
    }

    .register_submit_btn {
      padding: 2rem 0;
    }

    .auth_phone_wrapper {
      display: flex;
      flex-direction: row;
      align-content: center;
    }
  }
}
