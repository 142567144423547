.dark_bg_color1 {
  .manage_wrapper {
    .manage_header {
      background-color: var(--dark-bg-color2);

      .view_txt {
        color: white;
      }
    }

    .game_manage_tab {
      button {
        color: white;
      }

      .Mui-selected {
        color: #4d99ff !important;
      }
    }
  }
}


.game_extend_date_modal {
  .modal-content{
  overflow-y: unset!important;
  }
  .now_expire_date {
    padding: 1rem 0;
  }

  .select_wrapper {
    padding: 2rem 0;

    label {
      color: white;
    }
    svg {
      fill: white;
    }
    .extend_month_select {
      color: white;
    }

    fieldset {
      border-color: white;

      &:hover {
        border-color: #4d99ff;
      }
    }
  }
}

.minecraft_eula_agreement_modal{
  max-width: 700px!important;
  .modal-content {
    padding: 1rem;
    .agree_txt_wrapper{
      display: flex;
      flex-direction: column;
      padding: 1rem 0;
      span{
        margin-top: 0.5rem;
      }
    }
    .eula_btn_wrapper{
      padding: 1rem 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      button{
        padding: 0.6rem 0;
        width: 45%;
      }
    }
  }
}
