.product_wrapper {
  .product_welcome_box {
    margin-top: 3rem;
    width: 100%;
    min-height: 100px;
    background-color: #82b8ff;
    position: relative;
    overflow: hidden;

    #right_angle {
      max-width: 120px;
      position: absolute;
      right: 5%;
      top: 10%;
    }

    #center_angle {
      position: absolute;
      bottom: 0;
      left: 20%
    }

    .welcome_msg_wrapper {
      padding: 2rem;
      color: black;
      position: relative;
      z-index: 999;

      h4 {
        font-weight: bold;
      }

      .phone_event {
        cursor: pointer;

        &:hover {
          color: #ffffff;
        }
      }

    }
  }

  .product_top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 2rem 0;
    position: relative;

    h2 {
      font-weight: bold;
    }

    .MuiSpeedDialAction-staticTooltipLabel {
      min-width: 130px;
    }
  }

  .not_exist_wrapper {
    display: table-caption;
  }

  .server_filter_wrapper {
    background-color: #F7F9FA;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem;

    #server_status_sel {
      -webkit-appearance: inherit;
      appearance: auto;
      min-width: 150px;
      padding: 0.7rem 0.5rem;

      span {
        padding-left: 0.4rem;
      }
    }

    .server_search_wrapper {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      input {
        max-width: 300px;
        padding: 0.7rem 25px;
        background-size: 16px 16px;
        background-repeat: no-repeat;
        background-position: 5px 15px;
        margin-right: 3rem;
      }
    }
    #show_traffic_btn{
      margin-left: 1rem;
      min-width: 200px;
      height: 46px;
    }
  }

  .product_table_toolbar {
    background-color: #F7F9FA;
    transition: min-height 0.3s ease-out;
    overflow: hidden;
    height: auto;

    &.hide_toolbar {
      min-height: 0 !important;
    }

    .hide_toolbar {
      overflow: hidden;
      height: 0 !important;
    }
  }

  .instance_more_btn {
    max-width: 30px;
  }

  .product_table_btn {
    display: flex;
    flex-direction: row;

    button {
      color: #0485C8;
      background-color: #B9E6FD;
      margin-right: 1rem;
    }
  }

  .product_content {
    thead {
      background-color: #F7F9FA;

      th {
        border-bottom: none;

        span {
          color: #8297AA;
        }
      }
    }

    .server_table_row {
      cursor: pointer;

      &:hover {
        background-color: rgba(211, 233, 255, 0.3);
      }

      td, th {
        padding: 0 !important;
      }

      .server_check_item {
        padding: 0.5rem 0;
      }

      .server_item {
        height: 100%;
        padding: 1rem;

        &.server_label_item {
          max-width: 400px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          span {
            display: block;
          }

          small {
            color: rgba(0, 0, 0, 0.37);
          }
        }

        &.region_item, &.server_os_item {
          img {
            width: 30px;
          }

          span {
            margin-left: 0.4rem;
          }
        }


      }

      .instance_more_btn {
        .server_more_item {
          //padding: 1rem 0;
        }
      }
    }
  }
}

.mobile_table_wrapper {
  &.dark_mobile_table {
    .mobile_item {
      background-color: var(--dark-bg-color2);

      .instance_label_wrapper {
        small {
          margin-top: 0.3rem;
          color: #848484;
        }
      }

      .server_status_info {
        svg {
          fill: white;
        }
      }
    }
  }

  .mobile_item {
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.09);
    padding: 1rem;
    width: 100%;
    margin: 0;

    img {
      max-width: 28px;
    }

    .instance_label_wrapper {
      span {
        display: block;
        font-weight: bold;
      }

      small {
        color: var(--bright-txt-color);
      }
    }

    .server_status_info {
      text-align: center;

      .instance_status_show_wrapper {
        span {
          font-size: 0.8rem;
        }
      }

      .status_success {
        color: green;
      }

      .status_pending {
        color: orange;
      }
    }
  }
}

.server_pending {
  color: orange;
  font-weight: bold;
}

.server_run {
  color: green;
  font-weight: bold;
}

.server_stop {
  color: rgba(0, 0, 0, 0.63);
  font-weight: bold;
}

.web_hosting_bar_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  small {
    width: 20%;
  }

}

#web_hosting_storage_bar, #web_hosting_traffic_bar {
  width: 75%;
  height: 11px !important;
  border-radius: 15px;

  &.warning_bar {
    background-color: #fff9e5;

    .MuiLinearProgress-barColorPrimary {
      background-color: #fdaf00;
    }
  }

  &.error_bar {
    background-color: #FFE4E6;

    .MuiLinearProgress-barColorPrimary {
      background-color: red;
    }
  }

  &.save_traffic_bar {
    background-color: #b0d0ff;

    .MuiLinearProgress-barColorPrimary {
      background-color: #4D99FF;
    }
  }

  &.save_save_storage_bar {
    background-color: #E7FBF0;

    .MuiLinearProgress-barColorPrimary {
      background-color: #3DCC79;
    }
  }
}

.web_hosting_product_item {
  cursor: pointer;

  &:hover {
    background-color: rgba(211, 233, 255, 0.3);
  }
}

#web_hosting_modal, #game_hosting_modal {
  max-width: 700px !important;
}

.game_hosting_plan_modal, .game_extend_date_modal,.minecraft_eula_agreement_modal {
  .modal-content {
    background-color: var(--dark-bg-color2);
    color: white;

    .toast_box_wrapper {
      background-color: var(--dark-bg-color3);

      p {
        color: white;
      }
    }

    .plan_info_box {
      .upgrade_plan_info, .now_plan_info {
        background-color: var(--dark-bg-color3) !important;
      }
    }
  }
}

.web_hosting_plan_modal, .game_hosting_plan_modal, .game_extend_date_modal {
  .modal-content {
    max-height: 800px;
    overflow-y: scroll;
  }

  .MuiSlider-colorSecondary {
    color: #4d99ff !important;
  }

  .close {
    position: relative;
    float: left;
    display: inline-block;
    *display: inline;
    width: 50px;
    height: 50px;
    text-align: center;
    cursor: pointer;

  }

  .close3:after {
    content: "\00d7";
    font-size: 40pt;
    line-height: 15px;

  }

  .change_plan_wrapper {
    padding: 1rem;

    .change_plan_title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    button {
      margin-top: 2rem;
      padding: 1rem 0;
    }

    .toast_txt {
      .toast_title {
        font-weight: bold;
      }

      p {
        font-size: 0.875rem !important;
      }
    }

    .plan_info_wrapper {
      .plan_info_box {
        font-size: 0.9rem;
        margin-top: 1rem;
        justify-content: space-between;
        align-items: center;

        .now_plan_info, .upgrade_plan_info {
          padding: 1rem;
          background-color: var(--bright-bg-color);
        }
      }

      .plan_key_list {
        display: flex;
        flex-direction: column;

        span {
          font-weight: bold;
          margin-top: 0.4rem;
        }
      }
    }
  }

  .MuiSlider-mark {
    top: 50%;
    transform: translate(-50%, -50%);
    height: 15px !important;
  }

  .use_plan_txt {
    .use_plan_upgrade {
      margin-top: 0.5rem;
    }

    .use_plan_compare {
      text-align: right;
      color: #4d99ff;

      h4, span {
        font-weight: bold;
      }
    }
  }
}

.product_overview_modal{
  max-width: 1024px!important;
}

@media (max-width: 880px) {
  #show_traffic_btn{
    min-width: 40%!important;
  }
  .product_top {
    padding: 3rem 0.75rem !important;
  }

  .server_filter_wrapper {
    margin-top: 1rem;
    flex-direction: column !important;
    padding: 0 !important;

    .form-group {
      width: 50%;
    }

    .server_search_wrapper {
      margin: 1rem 0;
      justify-content: space-between !important;

      input {
        max-width: 193px !important;
      }
    }
  }
  .product_welcome_box {
    #right_angle {
      display: none;
    }
  }

  .monthly_traffic_usage_title {
    padding: 1rem 0;

    h3, span {
      font-size: 1rem !important;
    }
  }
  .web_hosting_bar_wrapper {
    small {
      font-size: 0.5rem;
    }
  }
  #game_hosting_modal, #web_hosting_modal {
    margin-top: 70px;

    .change_plan_wrapper {
      padding: 0 !important;
    }

    .use_plan_compare {
      h4 {
        font-size: 1.2rem !important;
      }
    }

    #upgrade_arrow_img {
      max-width: 50px;
      transform: rotate(90deg);
    }
  }
}
