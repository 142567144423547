.find_form_wrapper{
  padding: 2rem;
  h2{
    text-align: center;
    font-weight: bold;
  }
  p{
    padding: 0.5rem 1.2rem;
    font-size: 0.9rem;
    text-align: center;
  }
  .findId_btn{
    padding: 1.2rem 0;
    button{
      width: 100%;
      border-radius: 20px;
      padding: 0.7rem 0;
      background-color: #73B1E7FF;
      border: none;
      color: white;
    }
  }
  .send_email_pwd{
    padding: 1rem;
    span{
      text-align: center;
      font-weight: bold;
      display: block;
    }
  }
  .my_input_group{
    span{
      font-size: 0.8rem;
    }
  }
}
