.footer_wrapper {
  width: 100%;
  background: linear-gradient(90deg, #0C1E67, #3a4fbc);

  .footer_menu {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    .footer_menu_item {
      padding: 1.5rem 2.5rem;

      h4 {
        color: white;
        padding-bottom: 1rem;
      }

      ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
          text-align: left;
          margin: 0.5rem 0;

          a {
            color: #c1c1c1;

            &:hover {
              color: white;
            }
          }
        }
      }
    }

    .footer_contact {
      li {
        color: white;

        img {
          margin-right: 0.5rem;
        }
      }
    }
  }

  .footer_company {
    padding: 1rem 0;
    border-top: 1px solid #bebebe;
    color: white;

    .footer_logo {
      width: 50%;
      display: flex;
      flex-direction: row;
    }

    .footer_siteMap {
      width: 50%;
      ul {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        li{
          margin-right: 1rem;
          span{
            color: #a2a2a2;
            &:hover{
              color: white;
            }
          }
        }
      }
    }
  }


}
