.dark_mobile_nav_bar {
  .MuiDrawer-paper {
    background-color: var(--dark-bg-color3) !important;

    .visit_page {
      background-color: var(--dark-bg-color2) !important;
    }

    svg {
      fill: white;
    }

    span {
      color: white;
    }
  }
}

.not_exist_wrapper {
  position: relative;

  .text_wrapper {
    display: table-row;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    td {
      border: none;
      font-weight: bold;
      font-size: 1rem;
      color: #747474;
    }
  }
}

.my_table_wrapper {
  table {
    background: white;
    border: 1px solid #d7d7d7;

    th {
      color: #8592AF;
      border-bottom-color: #d7d7d7 !important;
    }

    th, td {
      padding: 1rem 0.7rem;
      vertical-align: middle;
    }

    .my_img_table_tr {
      cursor: pointer;
    }

    tbody {
      .txt_table_tr, .my_img_table_tr {
        &:hover {
          background-color: rgba(0, 0, 0, 0.09);
        }
      }
    }
  }

  .txt_table_tr {
    td {
      .txt_table_btn {
        background: none;
        border: none;
      }

      &:last-child {
        text-align: right;
      }

    }

    .view_txt {
      color: #0a77d5;
    }

    .view_input {
      .view_txt_field {
        width: 100%;
      }
    }

    .remove_ipv4_btn {
      background: none;
      border: none;

      &:hover {
        svg {
          color: #cb1a1a;
        }
      }
    }
  }

  .my_table_trash_btn {
    background: none;
    border: none;

    &:hover {
      svg {
        color: #cb1a1a;
      }
    }
  }

  .not_exist_wrapper {
    display: table-caption;
    background-color: white;
    border: 1px solid #d7d7d7;
  }

  .status_wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    span {
      margin-left: 0.4rem;
    }
  }

  .status_success {
    color: green;

    #status_circle {
      background-color: green;
    }
  }

  .status_pending {

    color: orange;

    #status_circle {
      background-color: orange;
    }
  }

  #status_circle {
    width: 10px;
    height: 10px;
    border-radius: 50px;
  }

  #snapShotOSImg {
    max-width: 30px;
  }

  #snapShotName {
    span, small {
      display: block;
    }

    span {
      font-weight: bold;
    }

    small {
      color: #a3a3a3;
    }

  }
}

.imgInfo_wrapper {
  &.game_img_info {
    margin-top: 2rem;
    .imgInfo_img {
      background-color: var(--dark-bg-color2) !important;
    }

    .imgInfo_label {
      label {
        color: white !important;
      }
    }
  }

  .imgInfo_img {
    background-color: #F7F7F8;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;

    img {
      width: 40px;
      fill: white;
    }
  }

  .imgInfo_label {
    .imgInfo_sub_img {
      width: 20px;
    }

    label {
      color: #808080;
    }

    span {
      color: #4596DD;
      font-weight: bold;
    }
  }
}

.confirm_popover_wrapper {
  min-width: 330px;
  max-width: 360px;

  .auth_pwd_popover {
    padding: 1rem;

    .auth_pwd_title {
      color: rgba(0, 0, 0, 0.6);
      font-size: 1rem;
      font-weight: bold;
      padding: 1rem 0;
    }

    button {
      margin-top: 2rem;
      width: 100%;
      padding: 0.4rem 0;
    }

    .auth_pwd_input {
      width: 100%;
      border-radius: 5px;
      padding: 0.8rem;
      border-color: rgba(0, 0, 0, 0.27);
    }
  }

  .popover_title {
    padding: 1rem 0;
    font-weight: bold;
  }

  #popover_cancel {
    color: black;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }

  .del_confirm_popover {
    padding: 1rem;

    span {
      margin: 0.5rem 0;
      color: red;
    }

    .del_btn {
      button {
        width: 100%;
        display: block;
        margin-bottom: 1rem;
      }

      #server_del_btn {
        color: white;

      }

    }
  }

  .common_confirm_popover {
    padding: 1rem;

    span {
      margin: 0.5rem 0;
      color: #0a77d5;
    }

    .confirm_btn {
      button {
        width: 100%;
        display: block;
        margin-bottom: 1rem;
      }

    }
  }
}

.view_txt_input_wrapper {
  .view_txt {
    display: flex;
    align-items: center;
    cursor: pointer;

    #view_txt_pen {
      margin-left: 1rem;
      font-size: 25px;
    }

    &:hover {
      color: #0a77d5!important;
    }
  }

  .view_input {
    display: flex;
    align-items: center;

    #view_check_icon {
      color: green;
    }

    #view_close_icon {
      color: red;
    }

    #view_check_icon, #view_close_icon {
      cursor: pointer;
    }
  }
}

#instance_more_wrapper {
  .game_more_menu_item {
    svg {
      margin-right: 0.6rem;
    }
  }

  .more_menu_item {
    color: rgba(0, 0, 0, 0.62);

    svg {
      margin-right: 0.6rem;
    }

    &:last-child {
      button {
        color: red;
      }
    }
  }
}

.toast_box_wrapper {
  margin: 2rem 0;
  padding: 0.6rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-left: 3px solid;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  .toast_txt {
    .toast_title {
      font-size: 1.2rem;
    }
  }

  hr {
    width: 3px;
    height: 100% !important;
  }

  #toast_line {
    width: 3px;
    height: 100%;
    background-color: black;
  }

  &.warning {
    border-color: orange;

    .toast_icon {
      color: orange;
    }
  }

  &.info {
    border-color: #0a77d5;

    .toast_icon {
      color: #0a77d5;
    }
  }

  &.error {
    border-color: red;

    .toast_icon {
      color: red;
    }
  }

  &.success {
    border-color: green;

    .toast_icon {
      color: green;
    }
  }

  .toast_icon {
    margin: 0 1rem;
  }

  p {
    color: rgba(0, 0, 0, 0.64);
  }
}

.my_input_group {
  .my_input_error_msg {
    font-size: 0.8rem !important;
  }
}

.confirm_modal_wrapper {
  background: white;
  padding: 1rem;
  border-radius: 10px;
  width: 30rem;
  max-width: 90vw;
  margin: 0 auto;

  .confirm_modal_title {
    font-size: 1.2rem;
    font-weight: bold;
    padding: 0.5rem 0;
  }

  .confirm_modal_content {
    padding: 0.5rem 0;

    p {
      font-size: 0.9rem;
    }
  }

  .confirm_btn_wrapper {
    margin-top: 0.5rem;
    padding: 1rem 0;
    display: flex;
    justify-content: flex-end;

    button {
      border: none;
      background-color: #0a77d5;
      border-radius: 5px;
      padding: 0.3rem 0.7rem;
      margin-right: 0.5rem;
      font-size: 0.875rem;
    }

    #confirm_cancel_btn {
      color: white;
      background-color: rgba(128, 128, 128, 0.57);
    }

    #confirm_ok_btn {
      color: #0485C8;
      background-color: #B9E6FD;
    }
  }
}

.confirm_modal_overlay {
  z-index: 9999 !important;
  background: rgb(0 0 0 / 44%) !important;
}

#swal2-title {
  color: black !important;
  font-size: 1.2rem;
  font-weight: bold;
  padding: 1rem 0;
}

#swal2-html-container {
  font-size: 0.9rem;
}

.swal2-confirm {
  color: #0485C8 !important;
  background-color: #B9E6FD !important;
}

.swal2-deny {
  color: white !important;
  background-color: rgba(128, 128, 128, 0.57) !important;
}

.not_exist_table_wrapper {
  padding: 3rem;

  .not_search_exist_wrapper {
    font-weight: bold;
    color: rgba(0, 0, 0, 0.42);
    padding: 3rem 0;
    text-align: center;
  }

  .not_exist_table_main_txt {
    h4 {
      font-weight: bold;
    }

    p {
      padding: 1rem 0;
      color: #6a6a6a;
    }

    button {
      margin: 2rem 0;
      background-color: #0593FF;
      color: white;
      font-weight: bold;
      padding: 0.6rem 4rem;
    }
  }

  .not_exist_table_advantage {
    padding: 2rem 0;

    .icon_wrapper {
      color: #0a77d5;
    }

    .description_wrapper {
      display: flex;
      flex-direction: column;

      span {
        font-size: 0.8rem;

        &:first-child {
          font-weight: bold;
        }

        &:last-child {
          color: #6a6a6a;
        }
      }
    }
  }

  .not_exist_img_wrapper {
    text-align: center;

    img {
      width: 60%;
    }
  }
}


.text_left_align {
  text-align: left;
}

.not_exist_txt_wrapper {
  min-height: 200px;
  text-align: center;
  position: relative;

  span {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 0.875rem;
  }
}

.card_avatar_wrapper{
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  img {
    width: 70%;
  }
}

#centos_panel_frame{
  width: 100vw;
  height: 100vh;
}


@media (max-width: 880px) {
  .toast_box_wrapper {
    .toast_txt {
      p {
        font-size: 0.8rem;
      }
    }
  }
  .gradient_wrapper {
    overflow: hidden;

    #gradient_main_logo {
      top: 5% !important;
      max-width: 100% !important;
    }

    .gradient_box {
      width: 90% !important;
    }

    .forget_pwd_wrapper {
      a {
        font-size: 0.8rem !important;
      }
    }

    .login_forget_pwd {
      font-size: 0.8rem !important;
    }

    .clause_wrapper {
      .clause_box {
        max-height: 70vh;
        overflow: scroll;

        span {
          font-size: 0.8rem !important;
        }
      }

    }
  }
  .not_exist_table_wrapper {
    padding: 1rem !important;

    .not_exist_table_main_txt {
      button {
        width: 100%;
      }
    }

    .not_exist_img_wrapper {
      display: none;
    }
  }
}

