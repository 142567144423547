.landing_main_section {
  padding-bottom: 2rem;
  height: 900px;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;

  .lottie_wrapper {
    position: absolute;
    width: 900px;
    right: 15%;
    top: 3%;
  }

  .txt_wrapper {
    position: absolute;
    left: 20%;
    top: 20%;

    h3, h2 {
      font-weight: bold;
    }

    h3 {
      color: #889afc;
    }

    h2 {
      margin: 1rem 0;
    }

    h2, .small_desc {
      color: white;
    }

    .small_desc {
      margin-top: 1.2rem;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      div {
        display: flex;
        flex-direction: column;

        &:last-child {
          margin-left: 1.2rem;
        }
      }
    }
  }
  .main_section_box{
    position: absolute;
    bottom: -100px;
    left: 50%;
    transform: translate(-50%,-50%);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .box{
      width: 32%;
      height: 100%;
      background-color: white;
      border-radius: 15px;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      .box_wrap{
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 1rem;
      }
      .box_img{
        img{
          width: 80px;
          height: 80px;
        }
      }
      .box_content{
        span{
          font-size: 1.3rem;
          font-weight: bold;
        }
      }
    }
  }
}

.landing_how_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 10rem;

  .how_title {
    h2 {
      text-align: center;
    }
  }
  .how_item_wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 3rem;
    .how_item{
      max-width: 370px;
      background-color: white;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 59px 0px;
      padding: 3rem 2rem;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      transition-duration: 1s;
      &:hover{
        -webkit-transform: translateY(-50px);
        -moz-transform: translateY(-50px);
        -ms-transform: translateY(-50px);
        -o-transform: translateY(-50px);
        transform: translateY(-50px);
      }
      h4{
        font-weight: bold;
        padding: 2rem 0;
      }
      p{
        text-align: center;
        color: #6f6f6f;
        line-height: 2rem;
      }
      a{
        color: #526dff;
        margin-top: 2rem;
        &:hover{
          color: #0C1E67;
        }
      }
    }
  }
}

.landing_faq_wrapper {
  background: linear-gradient(#fff, #EAE8F1);
  padding-top: 8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .faq_title {
    text-align: center;
    padding: 1.5rem 0;
    span{
      color: #a2a2a2;
    }
    h2{
      margin-top: 0.4rem;
      span{
        color: #526dff;
      }
    }
  }

  .faq_item_wrapper {
    width: 60%;
    padding: 4rem 0;
    ul {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-around;

      li {
        width: 45%;
        padding: 0.6rem 0;

        button {
          background-color: white;
          width: 100%;
          padding: 0.9rem 0.4rem;
          color: black;
          font-weight: bold;
          border : none;
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
          .faq_item {
            padding: 0 0.3rem;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          }
        }
      }
    }

    .rotate_arrow {
      transform: rotate(0);
    }

    img {
      transform: rotate(-90deg);
      margin-right: 1rem;
      width: 15px;
    }
    .toggle_wrapper{
      background-color: white;
      padding: 1rem;
      color: #6f6f6f;
    }
  }
}
.landing_price_wrapper {
  padding: 7rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .landing_price_title{
    font-weight: bold;
    h2{
      text-align: center;
    }
  }

  .landing_price_item{
    padding: 2rem 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .price_item {
      position: relative;
      width: 31%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      border-radius: 10px;
      border-style: solid;
      border-color: #526dff;
      border-width: 010px 0px 0px 0px;
      &:hover{
        border-color: #0C1E67;
      }
      img{
        position: absolute;
        bottom: 10px;
        right: 0;
      }
      .price_item_top{
        padding: 1.6rem 0;
        width: 100%;

        h3{
          font-weight: bold;
          padding-top: 1rem;
        }
        span{
          color: #6f6f6f;
        }
        h1{
          font-weight: 8000;
          padding: 1rem 0;
        }
      }
      .price_item_bottom{
        padding: 1rem 0;
        color: #6f6f6f;
        li{
          padding: 1rem 0;
        }
      }
    }
  }
}
