.game_manage_console{
  padding: 1rem;
  .console_wrapper{
    position: relative;
    min-height: 500px;
    background-color: rgba(0, 0, 0, 0.29);
    .console_writer{
      width: 100%;
      position: absolute;
      bottom: 10px;
      left: 0;
      right: 0;
      padding: 0 2rem;
      input{
        outline: none;
        color: white;
        padding: 0.4rem 0.3rem;
        border: 1px solid grey;
        background-color: var(--dark-bg-color2);
        width: 100%;
      }
    }
    .console_list{
      padding: 0 1rem 4rem 1rem;
      max-height: 650px;
      display:flex;
      flex-direction: column-reverse;
      overflow-y:auto;
    }
  }


}
