.game_server_backup{
  padding: 3rem 0;
  .game_make_backup_box{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    button{
      max-height: 50px;
      padding: 0.6rem 2rem;
    }
  }
  #game_backup_restore_btn{
    color: white;
    background-color: #4d99ff;
    margin-right: 1rem;
    border: 1px solid #4d99ff;
  }
  #game_backup_delete_btn{
    color: white;
    background-color: red;
    border: 1px solid red;
  }
}
